import React from "react";
import { graphql, StaticQuery } from "gatsby";
import {
  Navigation,
  Footer,
  CopyrightLabel,
  SiteMap,
  SocialMedia
} from "@fishrmn/fishrmn-components";
import { Link } from "gatsby";
import "semantic-ui-less/semantic.less";
import "./layout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFisherman {
          edges {
            node {
              businessName
              socialMedia {
                link
                type
              }
            }
          }
        }
        allFishermanImages {
          edges {
            node {
              heroImages {
                url
              }
              galleryImages {
                url
              }
            }
          }
        }
      }
    `}
    render={queryData => {
      const businessData = queryData.allFisherman.edges[0].node;
      const imageData = queryData.allFishermanImages.edges[0].node;
      const data = { ...businessData, ...imageData };

      return (
        <div>
          <Navigation
            links={[
              { pageTitle: "Home", slug: "/", internal: true },
              { pageTitle: "Contact", slug: "/contact/", internal: true },
              { pageTitle: "Catering", slug: "/catering/", internal: true },
              {
                pageTitle: "Order Online",
                slug:
                  "https://www.grubhub.com/restaurant/cristobal-mexican-grill-bar-1270-crabb-river-rd-richmond/1051744",
                internal: false
              }
            ]}
            backgroundColor={"primary"}
            bordered={false}
            centerMobileNavItems={true}
            evenSpacing={false}
            fixed={false}
            header={data.businessName}
            linksAs={"h4"}
            logo={false}
            primaryContentPosition={"left"}
            primaryContentCentered={false}
            internalLinkComponent={Link}
          />
          <div className="page-container">{children}</div>
          <Footer
            backgroundColor={"primary"}
            horizontalAlign={"center"}
            verticalAlign={"middle"}
            columns={[
              <CopyrightLabel
                company={data.businessName}
                phrase={"All Rights Reserved"}
              />,
              <SiteMap
                links={[
                  { pageTitle: "Home", slug: "/", internal: true },
                  { pageTitle: "Contact", slug: "/contact/", internal: true },
                  { pageTitle: "Catering", slug: "/catering/", internal: true },
                  {
                    pageTitle: "Order Online",
                    slug:
                      "https://www.grubhub.com/restaurant/cristobal-mexican-grill-bar-1270-crabb-river-rd-richmond/1051744",
                    internal: false
                  }
                ]}
                horizontal={true}
                bulleted={false}
                internalLinkComponent={Link}
              />,
              <SocialMedia
                socialMediaValues={data.socialMedia}
                buttonType={"circle"}
                centerHeader={false}
                colors={"secondary"}
                groupButtons={false}
                groupVertically={false}
                header={""}
                inverted={false}
                labelPosition={"right"}
                showLabels={false}
              />
            ]}
          />
        </div>
      );
    }}
  />
);
